import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Col } from "shards-react";

const PageTitleLogin = ({ title, subtitle, className, hrefsub, ...attrs }) => {
  const classes = classNames(
    className,
    "text-center",
  );

  return (
    <Col xs="12" className={classes} { ...attrs }>
      <h3 className="page-title">{title}</h3>
      <span className="text-uppercase page-subtitle">{subtitle}</span>
      <a href="/register" class="active">
        <span style = {{color: '#00b8d8'}} className="text-uppercase page-subtitle">{hrefsub}</span>
      </a>
    </Col>
  )
};

PageTitleLogin.propTypes = {
  /**
   * The page title.
   */
  title: PropTypes.string,
  /**
   * The page subtitle.
   */
  subtitle: PropTypes.string,

  hrefsub: PropTypes.string
};

export default PageTitleLogin;
