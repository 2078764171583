import React from "react";
import { withRouter } from "react-router-dom";

import matrizCriativaApi from "./../../services/matrizCriativaApi";

import {
  Button,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormInput,
  Alert
} from "shards-react";

function validateEmail(email) {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

class LoginInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      showMessage: false,
      errorPassword: false,
      open: false,
      errorEmail: false,
      msgAlert: "",
      typeAlert: ""
    };
  }

  loginNow = () => {
    if (this.state.password == "") {
      this.setState({
        typeAlert: "danger",
        showMessage: true,
        open: true,
        errorPassword: true,
        msgAlert: "Preencha sua senha"
      });
    } else if (!validateEmail(this.state.email)) {
      this.setState({
        showMessage: true,
        typeAlert: "danger",
        open: true,
        errorEmail: true,
        msgAlert: "Digite um email válido."
      });
    } else {
      this.login();
    }
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  login = async () => {
    try {
      let responseLogin = await matrizCriativaApi.post("/login", {
        login: this.state.email,
        password: this.state.password
      });
      if (responseLogin.data.status) {
        this.setState({
          typeAlert: "success",
          open: true,
          errorPassword: false,
          errorEmail: false,
          email: "",
          password: ""
        });
        localStorage.setItem("token", responseLogin.data.access_token);
        localStorage.setItem("type", responseLogin.data.type);

        this.props.history.push("/homePerfil");
      } else {
        this.setState({
          typeAlert: "danger",
          open: true,
          errorLabel: false,
          errorPassword: false,
          msgAlert: "Usuário ou senha incorretos"
        });
      }
    } catch (err) {
      this.setState({
        open: true,
        errorLabel: false,
        errorEmail: false,
        msgAlert: "Usuário não criado!"
      });
    }
  };

  render() {
    // console.log(this.state)
    return (
      <div>
        <Alert
          dismissible={this.dismiss}
          open={this.state.open}
          theme={this.state.typeAlert}
        >
          <span>{this.state.msgAlert}</span>
        </Alert>

        <span>Email</span>
        <InputGroup seamless className="mb-3">
          <InputGroupAddon type="prepend">
            <InputGroupText>
              <i className="material-icons">person</i>
            </InputGroupText>
          </InputGroupAddon>
          <FormInput
            invalid={this.state.errorEmail}
            placeholder="Email"
            value={this.state.email}
            onChange={this.handleChange("email")}
          />
        </InputGroup>
        <span>Senha</span>
        <InputGroup seamless className="mb-3">
          <FormInput
            invalid={this.state.errorPassword}
            type="password"
            onChange={this.handleChange("password")}
          />
          <InputGroupAddon type="append">
            <InputGroupText>
              <i className="material-icons">lock</i>
            </InputGroupText>
          </InputGroupAddon>
        </InputGroup>
        <a
          style={{ color: "gray", marginLeft: "67%" }}
          href="/login-forgot"
          class="active"
        >
          Esqueceu a senha ?
        </a>
        <Button
          onClick={this.loginNow}
          theme="primary"
          className="mb-2"
          style={{ width: "100%" }}
        >
          Entrar
        </Button>
      </div>
    );
  }
}

export default withRouter(LoginInfo);
