import React from "react";
import matrizCriativaApi from './../../services/matrizCriativaApi';
import NumberFormat from 'react-number-format';
import {
  Button,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormInput,
  Alert

} from "shards-react";


function validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}


class RegisterInfoFisical extends React.Component {

  constructor(props) {
    super(props); 
    this.dismiss = this.dismiss.bind(this);
    this.state = {
        name: "",
        birthday: "",
        cpf: "",
        email: "",
        phone: "",
        cep: "",
        street: "",
        number: "",
        neighborhood: "",
        complement: "",
        reference_point: "",
        password: "",
        password_confirm: "",
        showMessage: false,
        errorLabel: false,
        open: false,
        errorEmail:false,
        msgAlert: "",
        typeAlert: "",
    
    };

  }

handleChange = name => event => {
    this.setState({
        [name]: event.target.value,
    });
};

dismiss() {
    this.setState({ open: false });
  }


  
handleClickRegister = async () => {
    if (this.state.name == "" || this.state.cpf == "" || this.state.birthday == "" ||
        this.state.email == "" || this.state.phone == "" || this.state.street == "" || 
        this.state.number == "" || this.state.neighborhood == "" || this.state.cep == "" ||
        this.state.password == "" || this.state.password_confirm == "" ) {
        this.setState({
            typeAlert: "danger",
            showMessage: true,
            open: true,
            errorLabel: true,
            msgAlert: "Preencha os campos em branco"
        })
    } else if (!validateEmail(this.state.email)) {
        this.setState({
            showMessage: true,
            typeAlert: "danger",
            open: true,
            errorEmail:true,
            msgAlert: "Digite um email válido."
                    })
        }

        else if (this.state.password != this.state.password_confirm) {
            this.setState({
                
                showMessage: true,
                typeAlert: "danger",
                open: true,
                errorEmail:false,
                msgAlert: "As senhas não são iguais, por favor tente novamente."
                        })
            }
    else {

        this.handleRequestCloseSaveInst()

    }
};


handleRequestCloseSaveInst = async () => {
    try {
        let responseFisical= await matrizCriativaApi.post("/create/fisical-person-relationship", {
            name : this.state.name,
            birthday: this.state.birthday,
            cpf : this.state.cpf,
            login: this.state.email,
            password: this.state.password,
            profile_picture: "http://www.chazit.com/novo/imagens/unknown.png",
            email: this.state.email,
            phone: this.state.phone,
            street: this.state.street,
            number: this.state.number,
            neighborhood: this.state.neighborhood,
            complement: this.state.neighborhood,
            cep: this.state.cep,
            reference_point: this.state.reference_point
        });
    if(responseFisical.data.status){
        this.setState({ typeAlert: "success",  
                        open: true, 
                        errorLabel: false, 
                        errorEmail: false,
                        msgAlert: "Conta criada com sucesso !"})
        let responseMail= await matrizCriativaApi.post("/send/email-register", {
            email: this.state.email,
            login: this.state.email,
            password: this.state.password,
        });
        this.setState({
                        name : "",
                        birthday: "",
                        cpf : "",
                        login: "",
                        password: "",
                        password_confirm: "",
                        profile_picture: "",
                        email: "",
                        phone: "",
                        street: "",
                        number: "",
                        neighborhood: "",
                        complement: "",
                        cep: "",
                        reference_point: "",
                       });
                        
                        
                        
    }else {
        this.setState({
            open: true, 
            errorLabel: false, 
            errorEmail: false, 
            typeAlert: "danger",
            msgAlert: "Usuário não criado!" + responseFisical.data.message,
        });
    }
    }
    catch (err) {
        this.setState({
            open: true, 
            typeAlert: "danger",
            errorLabel: false, 
            errorEmail: false, 
            msgAlert: "Usuário não criado!",
        });
    }
}


  render() {
    return (
    <div >
    <Alert style = {{display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center'}} dismissible={this.dismiss} className="row col" open={this.state.open} theme={this.state.typeAlert}>
            <span>{this.state.msgAlert}</span>           
    </Alert>
    <div className = "row col">
    
    <div className = "row col-9 mr-4">
      <InputGroup seamless className="mb-3">
      <InputGroupAddon type="prepend">
        <InputGroupText>
          <i className="material-icons">person</i>
        </InputGroupText>
      </InputGroupAddon>
      <FormInput
        invalid	={(this.state.name == "" && this.state.errorLabel)}
        value = {this.state.name} 
        onChange={this.handleChange('name')} 
        placeholder="Nome completo" />
    </InputGroup>
    </div>

    <div className = "row col-3" style = {{paddingRight: "0px"}}>
    <InputGroup seamless className="mb-3">
      <InputGroupAddon type="prepend">
        <InputGroupText>
        <i className="material-icons">insert_invitation</i>
        </InputGroupText>
      </InputGroupAddon>
      <FormInput
        invalid	={(this.state.birthday == "" && this.state.errorLabel)}
        type = "date"
        value = {this.state.birthday} 
        onChange={this.handleChange('birthday')} 
        placeholder="Data de Nascimento" />
    </InputGroup>
    </div>

    <div className="row col-4  mr-5">

        <InputGroup seamless className="mb-3">
        <InputGroupAddon type="prepend">
            <InputGroupText>
                <i className="material-icons">fingerprint</i>
            </InputGroupText>
        </InputGroupAddon>

        <NumberFormat 
            invalid	={(this.state.cpf == "" && this.state.errorLabel)}
            placeholder="CPF" 
            value = {this.state.cpf} 
            customInput={FormInput} 
            onChange={this.handleChange('cpf')}
            format="###.###.###-##"/>  
        </InputGroup>
    </div>


    
    <div className="row col-3  mr-5">
        <InputGroup seamless className="mb-3">
            <InputGroupAddon type="prepend">
                <InputGroupText>
                    <i className="material-icons">call</i>
                </InputGroupText>
            </InputGroupAddon>

            <NumberFormat 
                invalid	={(this.state.phone == "" && this.state.errorLabel)}
                placeholder="Telefone"
                value={this.state.phone} 
                customInput={FormInput} 
                onChange={this.handleChange('phone')}
                format="(##)#####-####"/> 
        </InputGroup>
    </div>


    <div className="row col-4">

        <InputGroup seamless className="mb-3">
            <InputGroupAddon type="prepend">
                <InputGroupText>
                    <i className="material-icons">email</i>
                </InputGroupText>
            </InputGroupAddon>
            <FormInput
                invalid	={(this.state.email == "" && this.state.errorLabel || this.state.errorEmail)}
                type = "email"
                placeholder="Email" 
                value = {this.state.email} 
                onChange={this.handleChange('email')} />
        </InputGroup>
    </div>

    <div className="row col-3">

        <InputGroup seamless className="mb-3">
            <InputGroupAddon type="prepend">
                <InputGroupText>
                    <i className="material-icons">place</i>
                </InputGroupText>
            </InputGroupAddon>
            <NumberFormat
            invalid	={(this.state.cep == "" && this.state.errorLabel)} 
            placeholder="CEP" 
            value = {this.state.cep} 
            customInput={FormInput} 
            onChange={this.handleChange('cep')}
            format="##.###-###"/> 
        </InputGroup>
    </div>

    <div className="row col-6 ml-5 mr-5">

        <InputGroup seamless className="mb-3">
            <InputGroupAddon type="prepend">
                <InputGroupText>
                    <i className="material-icons">place</i>
                </InputGroupText>
            </InputGroupAddon>
            <FormInput 
                invalid	={(this.state.street == "" && this.state.errorLabel)}
                placeholder="Rua" 
                value = {this.state.street} 
                onChange={this.handleChange('street')} />
        </InputGroup>
    </div>

    <div className="row col-1" style = {{marginLeft: "4%"}}>
        <InputGroup seamless className="mb-3">
            <InputGroupAddon type="prepend">
                <InputGroupText>
                    <i className="material-icons">place</i>
                </InputGroupText>
            </InputGroupAddon>

            <NumberFormat
            invalid	={(this.state.number == "" && this.state.errorLabel)} 
            placeholder="Número" 
            value = {this.state.number} 
            customInput={FormInput} 
            onChange={this.handleChange('number')}
            format="########"/> 
        </InputGroup>
    </div>

    <div className="row col-3 mr-5">
        <InputGroup seamless className="mb-3">
            <InputGroupAddon type="prepend">
                <InputGroupText>
                    <i className="material-icons">place</i>
                </InputGroupText>
            </InputGroupAddon>
            <FormInput
                invalid	={(this.state.neighborhood == "" && this.state.errorLabel)}
                placeholder="Bairro" 
                value = {this.state.neighborhood} 
                onChange={this.handleChange('neighborhood')}  />
        </InputGroup>
    </div>

    <div className="row col-4  ml-5 mr-5">
        <InputGroup seamless className="mb-3">
            <InputGroupAddon type="prepend">
                <InputGroupText>
                    <i className="material-icons">place</i>
                </InputGroupText>
            </InputGroupAddon>
            <FormInput 
                placeholder="Complemento"  
                value = {this.state.complement} 
                onChange={this.handleChange('complement')} />
        </InputGroup>
    </div>
    <div className="row col-3" style = {{ paddingRight: "0.8%"}}>

        <InputGroup seamless className="mb-3">
            <InputGroupAddon type="prepend">
                <InputGroupText>
                    <i className="material-icons">place</i>
                </InputGroupText>
            </InputGroupAddon>
            <FormInput 
                placeholder="Ponto de Referência"  
                value = {this.state.reference_point} 
                onChange={this.handleChange('reference_point')} />
        </InputGroup>

    </div>


    <div className="row col-5 mr-5">
    <span>Senha - mínimo 8 caracteres</span>
        <InputGroup seamless className="mb-3">
            <InputGroupAddon type="prepend">
                <InputGroupText>
                    <i className="material-icons">lock</i>
                </InputGroupText>
        </InputGroupAddon>
            <FormInput
                invalid	={(this.state.password == "" && this.state.errorLabel)} 
                type = "password" 
                placeholder="Senha" 
                value = {this.state.password} 
                onChange={this.handleChange('password')} />
        </InputGroup>

    </div>


    <div className="row col-5 ml-5">
    <span>Confirme a senha</span>
        <InputGroup seamless className="mb-3">
            <InputGroupAddon type="prepend">
                <InputGroupText>
                    <i className="material-icons">lock</i>
                </InputGroupText>
        </InputGroupAddon>
            <FormInput
                invalid	={(this.state.password_confirm == "" && this.state.errorLabel)} 
                type = "password" 
                placeholder="Senha" 
                value = {this.state.password_confirm} 
                onChange={this.handleChange('password_confirm')} />
        </InputGroup>

    </div>


<Button onClick={this.handleClickRegister} style = {{marginLeft: "46%"}}> Criar minha Conta</Button>
</div>
</div>



    );
  }
}

export default RegisterInfoFisical;








