import React from "react";
import { Redirect } from "react-router-dom";

// Layout Types
import { DefaultLayout } from "./layouts/main";
import { LoginLayout } from "./layouts/login";
import { AdminLayout } from "./layouts/admin";

// Route Views
import BlogOverview from "./views/BlogOverview";
import Register from "./views/Register";

import UserProfileLite from "./views/UserProfileLite";
import AddNewPost from "./views/AddNewPost";
import DonationDream from "./views/DonationDream";
import Errors from "./views/Errors";
import ComponentsOverview from "./views/ComponentsOverview";
import Tables from "./views/Tables";
import BlogPosts from "./views/BlogPosts";
import Login from "./views/Login";
import LoginForgot from "./views/LoginForgot";
import EditDreams from "./views/EditDreams";

import Dreams from "./views/Dreams";
import MapPage from "./views/MapPage";
import Home from "./views/Home";
import HomePage from "./views/HomePage";

export default [
  {
    path: "/",
    exact: true,
    layout: LoginLayout,
    component: HomePage,
    private: false
  },
  {
    path: "/home",
    exact: true,
    layout: LoginLayout,
    component: HomePage,
    private: false
  },
  {
    path: "/admin",
    exact: true,
    layout: DefaultLayout,
    component: EditDreams,
    private: true
  },
  {
    path: "/map-page",
    exact: true,
    layout: LoginLayout,
    component: MapPage,
    private: false
  },
  {
    path: "/donation-dream",
    layout: LoginLayout,
    component: DonationDream,
    private: false
  },
  {
    path: "/register-dream",
    exact: true,
    layout: LoginLayout,
    component: Dreams,
    private: false
  },
  {
    path: "/homePerfil",
    exact: true,
    layout: DefaultLayout,
    component: Home,
    private: true
  },
  {
    path: "/blog-overview",
    layout: DefaultLayout,
    component: BlogOverview,
    private: false
  },
  {
    path: "/login",
    layout: LoginLayout,
    component: Login,
    private: false
  },
  {
    path: "/register",
    layout: LoginLayout,
    component: Register,
    private: false
  },
  {
    path: "/login-forgot",
    layout: LoginLayout,
    component: LoginForgot,
    private: false
  },

  {
    path: "/user-profile-lite",
    layout: DefaultLayout,
    component: UserProfileLite,
    private: true
  },
  {
    path: "/add-new-post",
    layout: DefaultLayout,
    component: AddNewPost,
    private: true
  },
  {
    path: "/errors",
    layout: DefaultLayout,
    component: Errors,
    private: false
  },
  {
    path: "/components-overview",
    layout: DefaultLayout,
    component: ComponentsOverview,
    private: false
  },
  {
    path: "/tables",
    layout: DefaultLayout,
    component: Tables,
    private: true
  },
  {
    path: "/blog-posts",
    layout: DefaultLayout,
    component: BlogPosts,
    private: true
  }
];
