import React from "react";
import { Link } from "react-router-dom";
import {
  NavItem,
  NavLink
} from "shards-react";

export default class LoginButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false
    };

    this.toggleUserActions = this.toggleUserActions.bind(this);
  }

  toggleUserActions() {
    this.setState({
      visible: !this.state.visible
    });
  }

  render() {
    return (

      <NavItem className="border-right border-left" style = {{width: "120px", paddingTop: "10px"}}>
        <NavLink tag={Link} to="/login"
          className="nav-link-icon text-center"
        >
          <span>
           Login
          </span>
        </NavLink>       
      </NavItem>
  
    );
  }
}
