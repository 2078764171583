import React from "react";
import { Marker } from "react-map-gl";
import { Tooltip, Button } from "shards-react";

// import { Container } from './styles';

export default class Markers extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = { open: false };
  }

  toggle() {
    this.setState({
      open: !this.state.open
    });
  }

  render() {
    return (
      <React.Fragment>
        {this.props.pointDreams.map(point => (
          <React.Fragment>
            <Marker
              key={point.id}
              longitude={point.longitude}
              latitude={point.latitude}
            >
              <Button
                pill
                theme="info"
                onClick={() =>
                  this.props.clickCard(
                    point.dream_id,
                    point.dream_title,
                    point.required_dream
                  )
                }
              >
                {point.dream_title.length < 30
                  ? point.dream_title
                  : point.dream_title.slice(0, 30) + "..."}
              </Button>
            </Marker>
          </React.Fragment>
        ))}
      </React.Fragment>
    );
  }
}
