import React from "react";
import { Container, Row, Col } from "shards-react";
import NavBarLogin from "../../components/layout/NavBarLogin/NavBarLogin";






const Login = ({children}) => (
  <Container fluid>
  <Row>
      {<NavBarLogin/>}
      {children}
  </Row>
</Container>
);


export default Login;
