import React from "react";
import { Container, Row, Col } from "shards-react"; 
import PageTitleLogin from "../components/common/PageTitleLogin";

import {
  Button,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormInput,
  
} from "shards-react";

const LoginForgot = () => (
 <Container fluid className="main-content-container px-4">

  <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>  
    <div className = "col-4" style = {{marginTop: "1%"}}>
    <Row noGutters className="page-header py-4">
      <PageTitleLogin title="Esqueceu a senha ?" subtitle="Informe seu email ou login que enviaremos um email com instruções para você criar uma nova senha."  md="12" className="ml-sm-auto mr-sm-auto" />
    </Row>
    <InputGroup seamless className="mb-3">
      <InputGroupAddon type="prepend">
        <InputGroupText>
          <i className="material-icons">person</i>
        </InputGroupText>
      </InputGroupAddon>
      <FormInput value="Email" onChange={() => {}} />
    </InputGroup>
    
    {/* TODO -> BOTAR VERIFICAÇÃO DE "NÃO SOU ROBO" */}
    <Button theme="primary" className="mb-2" style = {{width: "100%"}}>
        Enviar
    </Button>
    </div>
  </div>

</Container>
);



export default LoginForgot;
