import React from "react";
import { NavItem, NavLink } from "shards-react";
import { Link } from "react-router-dom";

export default class CreateDreamButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false
    };

    this.toggleNotifications = this.toggleNotifications.bind(this);
  }

  toggleNotifications() {
    this.setState({
      visible: !this.state.visible
    });
  }

  render() {
    return (
      <NavItem
        className="border-right border-left"
        style={{ width: "120px", paddingTop: "10px" }}
      >
        <NavLink
          tag={Link}
          to="/register-dream"
          className="nav-link-icon text-center"
        >
          <span>Criar Sonho</span>
        </NavLink>
      </NavItem>
    );
  }
}
