import React, { Component, Fragment } from "react";
import MapGl from "react-map-gl";
import Dimensions from "react-dimensions";
import debounce from "lodash/debounce";
import Markers from "./../components/Markers/Markers";
import DonationForm from "./../components/user-profile-lite/DonationForm";
import matrizCriativaApi from "./../../src/services/matrizCriativaApi";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Badge,
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  Progress
} from "shards-react";

const token =
  "pk.eyJ1IjoiY2Vsc29lcm5hbmkiLCJhIjoiY2syNnRzdGE5MDdzeTNobzFlMDlwbWx4eSJ9.duGNtZdw7MftrkhfUY_d3Q";

class MapPage extends Component {
  constructor(props) {
    super(props);
    this.updatePropertiesLocalization = debounce(
      this.updatePropertiesLocalization,
      500
    );
    this.state = {
      viewport: {
        latitude: -3.731819,
        longitude: -38.513489,
        zoom: 15,
        bearing: 0,
        pitch: 0
      },
      open: false,
      dream_id: null,
      title_dream: null,
      required_dream: null,
      dreams: [],
      markers: []
    };
  }

  componentDidMount() {
    this.getAllDreams();
    // this.loadMarkers();
  }

  updatePropertiesLocalization() {
    this.getAllDreams();
    // this.loadMarkers();
  }

  toggle = (dream_id, title_dream, required_dream) => {
    this.setState({
      open: !this.state.open,
      dream_id: dream_id,
      title_dream: title_dream,
      required_dream: required_dream
    });
  };
  getAllDreams = async () => {
    try {
      let response = await matrizCriativaApi.get("/search_all/dream");
      this.setState({ dreams: response.data });
      if (response.data) {
        const dreams_array = [];
        this.state.dreams.map((dream, i) => {
          dreams_array.push({
            key: i,
            latitude: parseFloat(dream.latitude),
            longitude: parseFloat(dream.longitude),
            dream_id: dream.id,
            dream_title: dream.title,
            required_dream: dream.required
          });
        });
        this.setState({ markers: dreams_array });

      }

    } catch (error) {
      console.log(error);
    }
  };
  renderCardDonation = () => {
    return (
      <div
        style={{
          display: "flex",
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          paddingBottom: "5%"
        }}
      >
        <DonationForm
          dream_id={this.state.dream_id}
          title={this.state.title_dream}
          required={this.state.required_dream}
        />
      </div>
    );
  };

  render() {
    const { open } = this.state;

    return (
      <Container
        style={{ margin: 0, padding: 0 }}
        fluid
        className="main-content-container"
      >
        <Fragment>
          <Modal position="hidden" open={open} toggle={this.toggle}>
            <ModalHeader>Doação</ModalHeader>
            <ModalBody style={{ padding: 0 }}>
              <Progress
                theme="primary"
                value={Math.random() * 100}
                style={{ marginTop: 0, height: "5px" }}
              />
              <div style={{ padding: 10 }}>
                {this.renderCardDonation()}
              </div>
            </ModalBody>
          </Modal>
        </Fragment>

        <div style={{ display: "flex", flex: 1 }}>
          <div className="col-7">
            <Row style={{ marginTop: "10px" }}>
              {this.state.dreams.map((dream, idx) => (
                <Col
                  style={{ marginBottom: "10px" }}
                  lg="12"
                  sm="12"
                  className="mb-12"
                  key={idx}
                >
                  <Card
                    small
                    onFocus
                    onBlur
                    className="card-post card-post--aside card-post--1"
                  >
                    <div
                      style={{
                        alignSelf: "center",
                        position: "absolute",
                        zIndex: 1000,
                        marginLeft: "80%",
                        height: "100%"
                      }}
                    >
                      <Button
                        theme="info"
                        style={{ height: "50%", width: "100%" }}
                        onClick={() =>
                          this.toggle(dream.id, dream.title, dream.required)
                        }
                      >
                        Ajude{" "}
                        <i className="material-icons">keyboard_arrow_right</i>
                      </Button>{" "}
                      <Button
                        theme="light"
                        style={{ height: "50%", width: "100%" }}
                        onClick={() =>
                          this.props.history.push({
                            pathname: "/donation-dream",
                            state: {
                              dream_id: dream.id,
                              title: dream.title,
                              required: dream.required
                            }
                          })
                        }
                      >
                        Veja as Doações
                        <i className="material-icons">keyboard_arrow_right</i>
                      </Button>
                    </div>

                    <div
                      className="card-post__image"
                      style={{
                        backgroundImage: `url('${require("../images/content-management/dream.png")}')`,
                        width: "50px",
                        height: "50px"
                      }}
                    >
                      <Badge
                        pill
                        className={`card-post__category bg-${"info"}`}
                      >
                        Sonho
                      </Badge>
                    </div>
                    <CardBody>
                      <h5 style={{ width: '70%' }} className="card-title">
                        <a className="text-fiord-blue" href="#">
                          {dream.title}
                        </a>
                      </h5>
                      <h6 style={{ margin: 0, padding: 0 }}>Sonho</h6>

                      <p
                        style={{ marginBottom: "5px", width:'70%' }}
                        className="card-text d-inline-block col-10"
                      >
                        {dream.goal}
                      </p>

                      <h6 style={{ margin: 0, padding: 0 }}>Do que preciso </h6>
                      <p style={{ width: '70%' }} className="card-text d-inline-block col-12">
                        {dream.required}
                      </p>
                    </CardBody>
                  </Card>
                </Col>
              ))}
            </Row>
          </div>

          <div style={{ display: "flex", flex: 1 }}>
            <MapGl
              width={"100%"}
              height={"100vh"}
              {...this.state.viewport}
              mapboxApiAccessToken={token}
              onViewportChange={viewport => this.setState({ viewport })}
              onViewStateChange={this.updatePropertiesLocalization.bind(this)}
            >
              <Markers
                // style={{width:300}}
                pointDreams={this.state.markers}
                clickCard={this.toggle}
              />
            </MapGl>
          </div>
        </div>
      </Container>
    );
  }
}

export default Dimensions()(MapPage);
