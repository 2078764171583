import React from "react";
import { Button } from "shards-react";
import Lottie from 'react-lottie';
import animationData from '../assets/animations/lf30_editor_ijN4R3.json'

var introRedeMatriz = "A partir da compreensão da construção dos conflitos nas organizações que a Rede Matriz Criativa apresenta habilidades certas e soluções onde todos os atores estão envolvidos."
var conteudo = "A colaboraçao não é uma atitude ou comportamento. Nem surge de uma intensionalidade ou princípio ético. A colaboração é uma dinâmica intrinseca à auto-regulagem do sistemas sociais humanos. A chamada inteligência colaborativa pode ser entendida como um conjunto de estratégias que venham a promover a reconfiguração das estruturas hierárquicas de uma organização, na abertura de oportunidades adequadas e possíveis, para que as pessoas possam apenas fluir o que já é inato ao ser humano, a colaboração."
var criatividade = "A partir de oficinas sociocriativas focadas para o fortalecimento de vínculos contemplando a valorização dos talentos pessoais e grupais, oportunizando a busca da autonomia e participação na transformação social, estimulando um desenvolvimento profissional. O poder da imaginação na transformação da realidade vivida atuando de forma intregal em todos os setores."
var impactoSocial = "Ajudamos afomentar  desenvolvimento local com base na formação de redes de cooperação."
var metodo = "Usamos métodos que fazem a famílias perceberem ques seus desejos individuais para a comunidade tambem são os de outras pessoas, e ue juntas poderão efetivar ações de melhoria para todos."



class HomePage extends React.Component {

  constructor(props) {
    super(props);
    this.state = { isStopped: false, isPaused: false };
  }

  render() {

    const buttonStyle = {
      display: 'block',
      margin: '10px auto'
    };

    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };
    return (
      <div style={{ width: '100%' }}>

        <div className="homeBackground" style={{ backgroundColor: '#FFFFFF', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div style={{ height: '90vh', display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', flexDirection: 'Column' }}>
            <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '30vw' }}>
              <div style={{ marginTop: '-15vh' }}>
                <img style={{ height: '40vh', width: '40vh', marginBottom: '-10vh' }} src={require("../images/aboutUs/logo.png")} />
                <div>
                  <Button onClick={() => this.props.history.push('/map-page')} size="lg" outline theme="secondary">
                    Ajude a semear um sonho
                 </Button>
                </div>
              </div>
              <div>
                <Lottie options={defaultOptions}
                  height={200}
                  width={200}
                />
              </div>
            </div>
          </div>
        </div>


        <div style={{ backgroundColor: '#F3F3F5', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div style={{ height: '90vh', display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', flexDirection: 'Column' }}>
            <h1 style={{ alignSelf: 'center', marginBottom: 60 }}>Rede Matriz Criativa</h1>
            <h3 style={{ alignSelf: 'center', marginTop: '-30px' }}>Ativando processos dinâmicos, fluxos e conexões.</h3>
          </div>
        </div>


        <div style={{ backgroundColor: '#F3F3F5', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div style={{ height: '90vh', display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
            <div style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              margin: '0px 32px',
              fontSize: '18px'
            }}
            >
              <i style={{ fontSize: '42px', marginBottom: '10px' }} className="material-icons">dashboard</i>
              <div style={{ maxWidth: '50%' }}>
                <p style={{ textAlign: 'center' }}> {introRedeMatriz} </p>
              </div>
            </div>

            <div style={{ flex: 2, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>

              <div style={{
                flex: 3,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                margin: '0px 32px'
              }}
              >
                <h2 style={{ marginBottom: '32px' }}>Conteúdo</h2>
                <p> {conteudo} </p>
              </div>

              <div style={{
                flex: 3,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                margin: '0px 32px'
              }}
              >
                <h2 style={{ marginBottom: '32px' }}>Criatividade</h2>
                <p> {criatividade} </p>
              </div>
            </div>
          </div>
        </div>


        <div style={{ backgroundColor: '#FFFFFF', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div style={{ height: '90vh', display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', flexDirection: 'row' }}>
            <div style={{ flex: 1, height: '90vh' }}></div>
            <div style={{ padding: '10px', flex: 3, height: '90vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
              <h1 style={{ alignSelf: 'center' }}><b>Impacto Social</b></h1>
              <h3 style={{ textAlign: 'center' }}> {impactoSocial} </h3>
            </div>
            <img style={{ flex: 2, height: '60vh' }} src={require("./../images/aboutUs/quadro.png")} />
            <div style={{ flex: 1, height: '90vh' }}></div>
          </div>
        </div>


        <div style={{ backgroundColor: '#F3F3F5', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div style={{ height: '90vh', display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', flexDirection: 'row' }}>
            <div style={{ flex: 1, height: '90vh' }}></div>
            <img style={{ flex: 1, height: '60vh' }} src={require("./../images/aboutUs/people.PNG")} />
            <div style={{ padding: '10px', flex: 2, height: '90vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
              <h1 style={{ alignSelf: 'center' }}>Nossa <b>vocação</b> pelo humano é nosso maior diferencial.</h1>
            </div>
          </div>
        </div>


        <div style={{ backgroundColor: '#FFFFFF', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div style={{ height: '90vh', display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', flexDirection: 'Column' }}>
            <h1 style={{ alignSelf: 'center' }}>Metodologia <b>Própria</b></h1>
            <h4 style={{ maxWidth: '60%', textAlign: 'center', margin: '0px 20px' }}>{metodo}</h4>
            <div style={{ backgroundColor: '#FFFFFF', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10px' }}>
              <img style={{ height: '60vh', width: '50vh' }} src={require("../images/aboutUs/case1.png")} />
              <img style={{ height: '50vh', width: '55vh' }} src={require("../images/aboutUs/case2.png")} />
            </div>


          </div>
        </div>


        <div style={{ backgroundColor: '#F3F3F5', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div style={{ height: '90vh', display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', flexDirection: 'row' }}>
            <img style={{ flex: 1, height: '60vh' }} src={require("./../images/aboutUs/orgulho.png")} />
          </div>
        </div>

      </div>)

  }
}

export default HomePage;
