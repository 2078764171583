import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button
} from "shards-react";
import matrizCriativaApi from "./../../src/services/matrizCriativaApi";

import PageTitle from "../components/common/PageTitle";

import React, { Component } from "react";

export default class DonationDream extends Component {
  constructor(props) {
    super(props);
    this.state = { donations_dreams: [], request_loading: false };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
    }
  }

  componentDidMount = () => {
    this.getDreamDonation();
  };

  getDreamDonation = async () => {
    try {
      const response = await matrizCriativaApi.get(
        `/find/donations_dreams/${this.props.location.state.dream_id}`
      );
      if (response.data) {
        this.setState({
          donations_dreams: response.data,
          request_loading: true
        });
      }
    } catch (error) {}
  };

  render() {
    return (
      <Container fluid className="main-content-container px-4">
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
          <PageTitle
            sm="4"
            title="Doações"
            subtitle={"Doações para " + this.props.location.state.title}
            className="text-sm-left"
          />
        </Row>

        {this.state.donations_dreams.length == 0 &&
        this.state.request_loading ? (
          <div className="error">
            <div className="error__content">
              <h2>Ops :(</h2>
              <h3>Nenhuma ajuda registrada.</h3>
              <p>
                Ninguém ajudou a realizar esse sonho ainda. Você pode ser o
                primeiro.
              </p>
              <Button
                onClick={() =>
                  this.props.history.push({
                    pathname: "/map-page"
                  })
                }
                pill
              >
                Seja o primeiro !
              </Button>
            </div>
          </div>
        ) : (
          <Row>
            <Col>
              <Card small className="mb-4">
                <CardHeader className="border-bottom">
                  <h6 className="m-0">Active Users</h6>
                </CardHeader>
                <CardBody className="p-0 pb-3">
                  <table className="table mb-0">
                    <thead className="bg-light">
                      <tr>
                        <th scope="col" className="border-0">
                          Doador
                        </th>
                        <th scope="col" className="border-0">
                          Contato
                        </th>
                        <th scope="col" className="border-0">
                          Doação
                        </th>
                        <th scope="col" className="border-0">
                          Quantidade
                        </th>
                        <th scope="col" className="border-0">
                          Endereço
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.donations_dreams.map((donation, i) => (
                        <tr>
                          <td>{donation.donor_name}</td>
                          <td>{donation.donor_contact}</td>
                          <td>{donation.donation_desc}</td>
                          <td>{donation.donated_amount}</td>
                          <td>{donation.address}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
      </Container>
    );
  }
}
