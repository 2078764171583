import { create } from "apisauce";

const token = localStorage.getItem("token");

export const matrizCriativaApi = create({
  // baseURL: "http://192.168.15.16:8000",
  // baseURL: "http://34.95.166.219",
  baseURL: "https://myappregister.tk:8080",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + token
  },
  timeout:10000,
});
matrizCriativaApi.addResponseTransform(response => {
  if (response.status === 401) {
    localStorage.removeItem("token");
    window.location.replace("/");
  }
  if (!response.ok) {
    throw response;
  }
});
export default matrizCriativaApi;
