import React from "react";
import matrizCriativaApi from "./../../services/matrizCriativaApi";

import NumberFormat from "react-number-format";
import {
  Button,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormInput,
  Alert,
  Collapse,
  FormCheckbox
} from "shards-react";

class DonationForm extends React.Component {
  constructor(props) {
    super(props);
    this.dismiss = this.dismiss.bind(this);
    this.toggleCollapse = this.toggleCollapse.bind(this);

    this.state = {
      donor_name: "",
      donor_contact: "",
      donation_desc: "",
      donated_amount: "",
      info_delivered: "",
      address: "",
      open: false,
      msgAlert: "",
      typeAlert: "",
      errorLabel: false,
      checked: false,
      collapse: false
    };
  }

  handleChangeCheck = () => {
    this.setState({ checked: !this.state.checked });
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  handleRequestSaveDonationDream = async () => {
    var moment = require("moment");

    console.log("antes de mandar a req", this.state);
    try {
      if (
        this.state.donor_name == "" ||
        this.state.donor_contact == "" ||
        this.state.donation_desc == "" ||
        this.state.donated_amount == ""
      ) {
        this.setState({
          typeAlert: "danger",
          errorLabel: true,
          open: true,
          msgAlert: "Preencha os campos em branco"
        });
      } else {
        let responseDreamDonation = await matrizCriativaApi.post(
          "/create/donation_dream",
          {
            donor_name: this.state.donor_name,
            donor_contact: this.state.donor_contact,
            donation_desc: this.state.donation_desc,
            donated_amount: this.state.donated_amount,
            info_delivered: this.state.info_delivered,
            donation_date: moment().toDate(),
            address: this.state.address,
            dream_id: this.props.dream_id
          }
        );
        if (responseDreamDonation.data.status) {
          this.setState({
            typeAlert: "success",
            open: true,
            msgAlert: "Doação feita com sucesso criado com sucesso !"
          });
        } else {
          this.setState({
            open: true,
            typeAlert: "danger",
            msgAlert: responseDreamDonation.data.message
          });
        }
      }
    } catch (err) {
      this.setState({
        open: true,
        typeAlert: "danger",
        msgAlert: "Doação não registrada!"
      });
    }
  };
  toggleCollapse() {
    this.setState({ collapse: !this.state.collapse });
  }

  dismiss() {
    this.setState({ open: false });
  }

  render() {
    return (
      <div style={{ marginTop: "3%" }}>
        <Alert
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center"
          }}
          dismissible={this.dismiss}
          open={this.state.open}
          theme={this.state.typeAlert}
        >
          <span>{this.state.msgAlert}</span>
        </Alert>

        <div
          style={{
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <Button
            style={{ marginBottom: "3%" }}
            outline
            pill
            small
            onClick={this.toggleCollapse}
          >
            {this.state.collapse ? (
              <i className="material-icons">keyboard_arrow_up</i>
            ) : (
              <i className="material-icons">keyboard_arrow_down</i>
            )}
            {" " + "Ver Sonho"}
          </Button>
          <Collapse open={this.state.collapse}>
            <div className="p-3 mb-3 border rounded">
              <h4>{"Sonho: " + this.props.title}</h4>
              <span>{"O que preciso: " + this.props.required}</span>
            </div>
          </Collapse>
        </div>

        <div
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "space-between"
          }}
        >
          <div className="row col-8">
            <InputGroup seamless className="mb-3">
              <InputGroupAddon type="prepend">
                <InputGroupText>
                  <i className="material-icons">person</i>
                </InputGroupText>
              </InputGroupAddon>
              <FormInput
                invalid={this.state.donor_name == "" && this.state.errorLabel}
                value={this.state.donor_name}
                onChange={this.handleChange("donor_name")}
                placeholder="Quem está doando? "
              />
            </InputGroup>
          </div>

          <div className="row col-4">
            <InputGroup seamless className="mb-3">
              <InputGroupAddon type="prepend">
                <InputGroupText>
                  <i className="material-icons">call</i>
                </InputGroupText>
              </InputGroupAddon>

              <NumberFormat
                invalid={
                  this.state.donor_contact == "" && this.state.errorLabel
                }
                placeholder="Contato"
                value={this.state.donor_contact}
                customInput={FormInput}
                onChange={this.handleChange("donor_contact")}
                format="(##)#####-####"
              />
            </InputGroup>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flex: 1
          }}
        >
          <InputGroup seamless className="mb-3">
            <InputGroupAddon type="prepend">
              <InputGroupText>
                <i className="material-icons">create</i>
              </InputGroupText>
            </InputGroupAddon>
            <FormInput
              invalid={this.state.donation_desc == "" && this.state.errorLabel}
              value={this.state.donation_desc}
              onChange={this.handleChange("donation_desc")}
              placeholder="Com o que você vai ajudar !?"
            />
          </InputGroup>
        </div>

        <div
          style={{
            display: "flex",
            flex: 1
          }}
        >
          <InputGroup seamless className="mb-3">
            <InputGroupAddon type="prepend">
              <InputGroupText>
                <i className="material-icons">my_location</i>
              </InputGroupText>
            </InputGroupAddon>
            <FormInput
              invalid={this.state.address == "" && this.state.errorLabel}
              value={this.state.address}
              onChange={this.handleChange("address")}
              placeholder="Onde está sua ajuda ?"
            />
          </InputGroup>
        </div>

        <div
          style={{
            display: "flex",
            width: "50%"
          }}
        >
          <InputGroup seamless className="mb-3">
            <InputGroupAddon type="prepend">
              <InputGroupText>
                <i className="material-icons">details</i>
              </InputGroupText>
            </InputGroupAddon>
            <FormInput
              invalid={this.state.donated_amount == "" && this.state.errorLabel}
              value={this.state.donated_amount}
              onChange={this.handleChange("donated_amount")}
              placeholder="Quantidade"
            />
          </InputGroup>
        </div>

        <FormCheckbox
          toggle
          small
          checked={this.state.checked}
          onChange={this.handleChangeCheck}
        >
          É necessário transporte ?
        </FormCheckbox>
        {this.state.checked ? (
          <div
            style={{
              display: "flex",
              flex: 1
            }}
          >
            <InputGroup seamless className="mb-3">
              <InputGroupAddon type="prepend">
                <InputGroupText>
                  <i className="material-icons">emoji_transportation</i>
                </InputGroupText>
              </InputGroupAddon>
              <FormInput
                invalid={
                  this.state.info_delivered == "" && this.state.errorLabel
                }
                value={this.state.info_delivered}
                onChange={this.handleChange("info_delivered")}
                placeholder="Informações sobre localização e entrega."
              />
            </InputGroup>
          </div>
        ) : null}

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Button onClick={this.handleRequestSaveDonationDream}>
            {" "}
            Ajudar Sonho
          </Button>
        </div>
      </div>
    );
  }
}

export default DonationForm;
