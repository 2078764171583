import React from "react";
import matrizCriativaApi from "./../../services/matrizCriativaApi";
import NumberFormat from "react-number-format";
import {
  Button,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormInput,
  Alert
} from "shards-react";

class RegisterDreamForm extends React.Component {
  constructor(props) {
    super(props);
    this.dismiss = this.dismiss.bind(this);

    this.state = {
      title: "",
      user_name: "",
      goal: "",
      link_image: "",
      address: "",
      phone: "",
      required: "",
      open: false,
      msgAlert: "",
      typeAlert: "",
      errorLabel: false,
      disableButton: false
    };
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  handleRequestSaveDream = async () => {
    var moment = require("moment");

    console.log(moment());
    try {
      this.setState({ disableButton: true })
      if (
        this.state.title == "" ||
        this.state.goal == "" ||
        this.state.user_name == "" ||
        this.state.address == "" ||
        this.state.phone == "" ||
        this.state.required == ""
      ) {
        this.setState({
          typeAlert: "danger",
          errorLabel: true,
          open: true,
          msgAlert: "Preencha os campos em branco",
          disableButton: false
        });
      } else {
        let responseDream = await matrizCriativaApi.post("/create/dream", {
          title: this.state.title,
          goal: this.state.goal,
          id_category: 1,
          link_image: "null",
          date_created: "null",
          date_limited: "date_limited",
          user_name: this.state.user_name,
          address: this.state.address,
          phone_number: this.state.phone,
          required: this.state.required,
          latitude: Math.random() * (-3.7296379 - -3.7732479) + -3.7732479,
          longitude: Math.random() * (-38.4895753 - -38.5629574) + -38.5629574,
          enable: true
        });
        if (responseDream.data.status) {
          this.setState({
            typeAlert: "success",
            open: true,
            msgAlert: "Sonho criado com sucesso !",
            disableButton: false

          });
        } else {
          this.setState({
            open: true,
            typeAlert: "danger",
            msgAlert: responseDream.data.message,
            disableButton: false

          });
        }
      }
    } catch (err) {
      this.setState({
        open: true,
        typeAlert: "danger",
        msgAlert: "Sonho não criado!",
        disableButton: false
      });
    }
  };

  dismiss() {
    this.setState({ open: false });
  }

  render() {
    return (
      <div>
        <Alert
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center"
          }}
          dismissible={this.dismiss}
          className="col-12"
          open={this.state.open}
          theme={this.state.typeAlert}
        >
          <span>{this.state.msgAlert}</span>
        </Alert>

        <div
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "space-between"
          }}
        >
          <div className="row col-5">
            <InputGroup seamless className="mb-3">
              <InputGroupAddon type="prepend">
                <InputGroupText>
                  <i className="material-icons">person</i>
                </InputGroupText>
              </InputGroupAddon>
              <FormInput
                invalid={this.state.user_name == "" && this.state.errorLabel}
                value={this.state.user_name}
                onChange={this.handleChange("user_name")}
                placeholder="De quem é o sonho ? "
              />
            </InputGroup>
          </div>

          <div className="row col-5">
            <InputGroup seamless className="mb-3">
              <InputGroupAddon type="prepend">
                <InputGroupText>
                  <i className="material-icons">emoji_emotions</i>
                </InputGroupText>
              </InputGroupAddon>
              <FormInput
                invalid={this.state.title == "" && this.state.errorLabel}
                value={this.state.title}
                onChange={this.handleChange("title")}
                placeholder="Que sonho é esse ? "
              />
            </InputGroup>
          </div>

          <div style={{ padding: 0 }} className="col-2">
            <Button
              style={{ paddingTop: 7, paddingBottom: 7, width: "100%" }}
              outline
              theme="accent"
            >
              <i style={{ fontSize: 15 }} className="material-icons">
                add_photo_alternate
              </i>
              {" " + "Adicione uma foto"}
            </Button>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flex: 1
          }}
        >
          <InputGroup seamless className="mb-3">
            <InputGroupAddon type="prepend">
              <InputGroupText>
                <i className="material-icons">create</i>
              </InputGroupText>
            </InputGroupAddon>
            <FormInput
              size={"lg"}
              invalid={this.state.goal == "" && this.state.errorLabel}
              value={this.state.goal}
              onChange={this.handleChange("goal")}
              placeholder="Descreva o sonho com um pouco de detalhes !"
            />
          </InputGroup>
        </div>

        <div
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "space-between"
          }}
        >
          <div className="row col-8">
            <InputGroup seamless className="mb-3">
              <InputGroupAddon type="prepend">
                <InputGroupText>
                  <i className="material-icons">my_location</i>
                </InputGroupText>
              </InputGroupAddon>
              <FormInput
                invalid={this.state.address == "" && this.state.errorLabel}
                value={this.state.address}
                onChange={this.handleChange("address")}
                placeholder="Onde está o sonho ?"
              />
            </InputGroup>
          </div>
          <div className="row col-4">
            <InputGroup seamless className="mb-3">
              <InputGroupAddon type="prepend">
                <InputGroupText>
                  <i className="material-icons">call</i>
                </InputGroupText>
              </InputGroupAddon>

              <NumberFormat
                invalid={this.state.phone == "" && this.state.errorLabel}
                placeholder="Telefone"
                value={this.state.phone}
                customInput={FormInput}
                onChange={this.handleChange("phone")}
                format="(##)#####-####"
              />
            </InputGroup>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flex: 1
          }}
        >
          <InputGroup seamless className="mb-3">
            <InputGroupAddon type="prepend">
              <InputGroupText>
                <i className="material-icons">live_help</i>
              </InputGroupText>
            </InputGroupAddon>
            <FormInput
              size={"lg"}
              invalid={this.state.required == "" && this.state.errorLabel}
              value={this.state.required}
              onChange={this.handleChange("required")}
              placeholder="O que é necessário para realizar esse sonho ?"
            />
          </InputGroup>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Button disabled={this.state.disableButton} onClick={this.handleRequestSaveDream}> Criar Sonho</Button>
        </div>
      </div>
    );
  }
}

export default RegisterDreamForm;
