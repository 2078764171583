import React from "react";
import { Container, Row, Col } from "shards-react";


import PageTitleLogin from "../components/common/PageTitleLogin";

import LoginInfo from "../components/user-profile-lite/LoginInfo";




const Login = () => (
 <Container fluid className="main-content-container px-4">

  <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>  
    <div className = "col-4" style = {{marginTop: "1%"}}>
    <Row noGutters className="page-header py-4">
      <PageTitleLogin title="Login" subtitle="Não tem conta ?" hrefsub = "Cadastre-se" md="12" className="ml-sm-auto mr-sm-auto" />
    </Row>
      <LoginInfo />
    </div>
  </div>

</Container>
);



export default Login;
