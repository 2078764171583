import React from "react";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";

import matrizCriativaApi from './../../services/matrizCriativaApi';

const isAuthenticated  = () => {
    const token = localStorage.getItem('token');

    if(token){
        return true
    }
    return false
};

export default ({component: Component, ...rest}) => (
    <Route {...rest} 
        render={props => 
            isAuthenticated() ? (
            <Component {...props} />
            ) : (
            <Redirect to={{ pathname: '/', state: { from: props.location } }}/>
            )
        }
    />
)