import React from "react";
import { Container, Row, Col, Card, CardBody, Badge } from "shards-react";

import PageTitle from "../components/common/PageTitle";
import RegisterDreamForm from "../components/user-profile-lite/RegisterDreamForm";

export default class Dreams extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <Container fluid className="main-content-container px-4">
        <Row noGutters className="page-header py-4" center="xs">
          <PageTitle
            sm="4"
            title="Sonhos"
            subtitle="Cadastre um sonho e ajude a realiza-los"
            className="text-sm-left"
          />
        </Row>
        <RegisterDreamForm />
      </Container>
    );
  }
}
