export default function() {
  return [
    {
      title: "Inicio",
      to: "/home",
      htmlBefore: '<i class="material-icons">home</i>',
      htmlAfter: ""
    },
    {
      title: "Editar Sonhos",
      to: "/admin",
      htmlBefore: '<i class="material-icons">edit</i>',
      htmlAfter: ""
    }

    // {
    //   title: "Sonhos",
    //   htmlBefore: '<i class="material-icons">vertical_split</i>',
    //   to: "/blog-posts",
    // },
    // {
    //   title: "Serviços",
    //   htmlBefore: '<i class="material-icons">note_add</i>',
    //   to: "/add-new-post",
    // },
    // {
    //   title: "Comunidades",
    //   htmlBefore: '<i class="material-icons">group</i>',
    //   to: "/user-profile-lite",
    // },
    // {
    //   title: "Sobre nós",
    //   htmlBefore: '<i class="material-icons">help</i>',
    //   to: "/tables",
    // },

    // {
    //   title: "Contato",
    //   htmlBefore: '<i class="material-icons">phone</i>',
    //   to: "/home",
    // }
  ];
}
