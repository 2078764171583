import React from "react";
import { Container, Row, FormSelect } from "shards-react"; 
import PageTitleLogin from "../components/common/PageTitleLogin";
import RegisterInfoFisical from "../components/user-profile-lite/RegisterInfoFisical";
import RegisterInfoLegal from "../components/user-profile-lite/RegisterInfoLegal";


class Register extends React.Component {
    constructor(props){
        super(props)
        this.state =  {
            selectTypeForm: "fiscal"
        }

        this.handleChange = this.handleChange.bind(this);

    }  


    componentDidMount() {


    }

    componentWillMount (){


    }


    handleChange(e) {
      console.log(e.target.value)
      this.setState({
        selectTypeForm: e.target.value
        })
    }

    render(){
        return (

          <Container fluid className="main-content-container px-4">

          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>  
            <div className = "col-4" style = {{marginTop: "1%"}}>
                
            <Row noGutters className="page-header py-4">
              <PageTitleLogin title="Faça seu Cadastro" subtitle="Entre na maior rede criativa no mundo."  md="12" className="ml-sm-auto mr-sm-auto" />
              <FormSelect onChange={this.handleChange}  style = {{marginTop: "5%"}} >
              <option value="fiscal">Pessoa Física</option>
              <option value="legal">Pessoa Jurídica</option>
            </FormSelect>   
            </Row>
            </div>  
            
          </div>
        
          {this.state.selectTypeForm == "fiscal" ? <RegisterInfoFisical /> : <RegisterInfoLegal />}
        
        
          </Container>


        );


    }

}


export default Register;
