import React from "react";
import { Nav } from "shards-react";
import CreateAccountButton from "./CreateAccountButton";
import LoginButton from "./LoginButton";
import CreateDreamButton from "./CreateDreamButton";

export default () => (
  <Nav navbar className="flex-row">
    <CreateDreamButton />
    <CreateAccountButton />
    <LoginButton />
  </Nav>
);
