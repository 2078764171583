import React, { Component, Fragment } from "react";
import matrizCriativaApi from "./../../src/services/matrizCriativaApi";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Badge,
  Alert,
  Button
} from "shards-react";

class EditDreams extends Component {
  constructor(props) {
    super(props);
    this.dismiss = this.dismiss.bind(this);

    this.state = {
      open: false,
      required_dream: null,
      dreams: [],
      msgAlert: "",
      open: false,
      typeAlert: ""
    };
  }

  componentDidMount() {
    this.getAllDreams();
    // this.loadMarkers();
  }

  updatePropertiesLocalization() {
    this.getAllDreams();
  }

  deleteDream = async dream_id => {
    let response = await matrizCriativaApi.post("/disable/dream", {
      id: dream_id,
      enable: false
    });
    if (response.data.status) {
      this.setState({
        typeAlert: "success",
        open: true,
        msgAlert: "Doação apagada com sucesso !"
      });
      this.getAllDreams();
    } else {
      this.setState({
        typeAlert: "danger",
        open: true,
        msgAlert: "Doação não apagada !"
      });
    }
  };

  dismiss() {
    this.setState({ open: false });
  }
  getAllDreams = async () => {
    try {
      let response = await matrizCriativaApi.get("/search_all/dream");
      console.log(response);
      if (response.data) {
        this.setState({ dreams: response.data });
      }
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    const { open } = this.state;
    const typeUser = localStorage.getItem("type");

    return (
      <Container
        style={{ margin: 0, padding: 0 }}
        fluid
        className="main-content-container"
      >
        <Alert
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center"
          }}
          dismissible={this.dismiss}
          open={this.state.open}
          theme={this.state.typeAlert}
        >
          <span>{this.state.msgAlert}</span>
        </Alert>
        {typeUser != "Admin" ? (
          <div className="error">
            <div className="error__content">
              <h2>Ops :(</h2>
              <h3>Página dedicada apenas para o admin.</h3>
              <p>Entre em contato com o administrador da página.</p>
            </div>
          </div>
        ) : (
          <div style={{ display: "flex", flex: 1 }}>
            <div className="col-12">
              <Row style={{ marginTop: "10px" }}>
                {this.state.dreams.map((dream, idx) => (
                  <Col
                    style={{ marginBottom: "10px" }}
                    lg="12"
                    sm="12"
                    className="mb-12"
                    key={idx}
                  >
                    <Card
                      small
                      onFocus
                      onBlur
                      className="card-post card-post--aside card-post--1"
                    >
                      <div
                        style={{
                          alignSelf: "center",
                          position: "absolute",
                          zIndex: 1000,
                          marginLeft: "80%",
                          height: "100%"
                        }}
                      >
                        <Button
                          theme="danger"
                          style={{ height: "50%", width: "100%" }}
                          onClick={() => this.deleteDream(dream.id)}
                        >
                          Delete <i className="material-icons">delete</i>
                        </Button>{" "}
                        <Button
                          theme="light"
                          style={{ height: "50%", width: "100%" }}
                          onClick={() =>
                            this.props.history.push({
                              pathname: "/donation-dream",
                              state: {
                                dream_id: dream.id,
                                title: dream.title,
                                required: dream.required
                              }
                            })
                          }
                        >
                          Veja as Doações
                          <i className="material-icons">keyboard_arrow_right</i>
                        </Button>
                      </div>

                      <div
                        className="card-post__image"
                        style={{
                          backgroundImage: `url('${require("../images/content-management/dream.png")}')`,
                          width: "50px",
                          height: "50px"
                        }}
                      >
                        <Badge
                          pill
                          className={`card-post__category bg-${"info"}`}
                        >
                          Sonho
                        </Badge>
                      </div>
                      <CardBody>
                        <h5 className="card-title">
                          <a className="text-fiord-blue" href="#">
                            {dream.title}
                          </a>
                        </h5>
                        <h6 style={{ margin: 0, padding: 0 }}>Sonho</h6>

                        <p
                          style={{ marginBottom: "5px" }}
                          className="card-text d-inline-block col-10"
                        >
                          {dream.goal}
                        </p>

                        <h6 style={{ margin: 0, padding: 0 }}>
                          Do que preciso{" "}
                        </h6>
                        <p className="card-text d-inline-block col-12">
                          {dream.required}
                        </p>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>
            </div>
          </div>
        )}
      </Container>
    );
  }
}

export default EditDreams;
