import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Container, Navbar } from "shards-react";

import NavbarSearch from "./NavbarSearch";
import NavbarNav from "./NavbarNav/NavbarNav";
import LogoActions from "./LogoActions"; 

const NavBarLogin = ({ layout, stickyTop }) => {
  const classes = classNames(
    "main-navbar",
    "bg-white",
    stickyTop && "sticky-top"
  );

  return (
    <div className={classes} style = {{width: "100%"}}>
        <Navbar type="light" className="align-items-stretch flex-md-nowrap p-0">
          <LogoActions/>
          <NavbarSearch />
          <NavbarNav />
        </Navbar>
    </div>
  );
};

NavBarLogin.propTypes = {
  /**
   * The layout type where the MainNavbar is used.
   */
  layout: PropTypes.string,
  /**
   * Whether the main navbar is sticky to the top, or not.
   */
  stickyTop: PropTypes.bool
};

NavBarLogin.defaultProps = {
  stickyTop: true
};

export default NavBarLogin;
